import "./property.css"
import  {SliderImages}  from '../../components/SliderImages/SliderImages';
import Navbar2 from "../../components/Nav/Navbar";
import {IoCrop, IoLocationOutline, IoBedOutline, IoCarSportOutline, IoCheckmarkSharp, IoHomeOutline, IoCloseOutline, IoAlertCircle} from 'react-icons/io5';
import {TfiRulerAlt2} from 'react-icons/tfi';
import {MdOutlineShower} from 'react-icons/md';
import {TbBath, TbBone, TbSofa} from 'react-icons/tb';
import {HiOutlineBellAlert} from 'react-icons/hi2';
import {GiHomeGarage} from 'react-icons/gi';
import { Footer } from "../../components/Footer/Footer";
import { CompanyInfo } from "../../components/CompanyInfo/CompanyInfo";
import { NewScheduling } from "../../components/NewScheduling/NewScheduling";
import { NewMessageProperty } from "../../components/NewMessageProperty/NewMessageProperty";
import { NewFavorite } from "../../components/NewFavorite/NewFavorite";
import { useParams } from "react-router-dom";
import { useFetch } from "../../hooks/useFetch";
import { NewShare } from "../../components/NewShare/NewShare";
import api from "../../services/api";
import { NewAlertClient } from "../../components/NewAlertClient/NewAlertClient";
import { DownloadApp } from "../../components/DownloadApp/DownloadApp";
import { useEffect, useState } from "react";
import Modal from 'react-modal';
import ReactTooltip from 'react-tooltip';
import {toast} from 'react-toastify';
import Helmet from 'react-helmet'

export function Property() {
    const Local = localStorage.getItem("suachave");
    const user = JSON.parse(Local);

    const {id} = useParams()
    const [data, setData] = useState([]);

    const [name, setName] = useState("");
    const [whatsapp, setWhatsapp] = useState("");
    const [email, setEmail] = useState("");

    const [isOpenModal, setIsOpenModa] = useState(false);


    useEffect(() => {
        async function loadPlains() {
            await api.get(`/property/${id}`).then((res) => {
                if(res.data.length === 0) {
                    window.open("/imovel-nao-encontrado", "_self");
                }
                setData(res.data[0]);

            }).catch((err) => {
                console.log(err);
            });
        }
        loadPlains() 
    },[]);


    if(data.length === 0) {
        return (
            <h5>Carregando...</h5>
        )
    }



    console.log(data?.status, data?.district, data?.city, data?.uf, data?.type, data?.subType, data?.bedroom, data?.suite, data?.restroom, data?.garage, data?.pets, data?.furnished, data?.idProperty)

    function handleOpenModal(e) {
        e.preventDefault();
          setIsOpenModa(true)
        }
      
        function handleCloseModal(e) {
          e.preventDefault();
          setIsOpenModa(false);
        }



    setTimeout(() => {
        if(data?.length === 0) {
            window.open("/home", "_self") 
        }
    }, "2000")


              const valueRent = parseInt(data?.priceRent?.replace(/[^0-9]/gi, ""));
              const valueText = valueRent.toString()
              const valueTextFormat = valueText?.replace("00", "");


              const valueconcominium = parseInt(data?.condominium?.replace(/[^0-9]/gi, ""));
              const valueTextconcominium = valueconcominium.toString()
              const valueTextconcominiumFormat = valueTextconcominium?.replace("00", "")
      
              const valueIptu = parseInt(data?.iptu?.replace(/[^0-9]/gi, ""));
              const valueTextIptu = valueIptu.toString()
              const valueTextIptuFormat = valueTextIptu?.replace("00", "")
      
              const valueOtherPrices = parseInt(data?.otherPrices?.replace(/[^0-9]/gi, ""));
              const valueTextOtherPrices = valueOtherPrices.toString()
              const valueTextOtherPricesFormat = valueTextOtherPrices?.replace("00", "")
            
              

    const valuesRent =[
        {
            id: "condominium",
            value: data?.condominium === "" ? "0,00" : valueTextconcominiumFormat
            },
            {
            id: "iptu",
            value: data?.iptu === "" ? "0,00" : valueTextIptuFormat
            },
            {
            id: "otherPrices",
            value: data?.otherPrices === "" ? "0,00" : valueTextOtherPricesFormat
            }
]
    const valuesCharges =[
        {
        id: "condominium",
        value: data?.condominium === "" ? "0,00" : valueTextconcominiumFormat
        },
        {
        id: "iptu",
        value: data?.iptu === "" ? "0,00" : valueTextIptuFormat
        },
        {
        id: "otherPrices",
        value: data?.otherPrices === "" ? "0,00" : valueTextOtherPricesFormat
        }
]

    const valuesTotalRent = valuesRent?.reduce(function (acumulador, objetoAtual){
        return acumulador + parseFloat(objetoAtual.value);
      }, 0);
    const valuesTotalCharges = valuesCharges?.reduce(function (acumulador, objetoAtual){
        return acumulador + parseFloat(objetoAtual.value);
      }, 0);

      var ResultBRL = valuesTotalRent.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})
      var ResultBRLCharges = valuesTotalCharges.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})

      const dataProperty = {
        idPropery: data?.id,
        adress: `${data?.road} - ${data?.district} - ${data?.city} - ${data?.uf}`,
        status: data?.status,
        priceRent: data?.priceRent,
        priceSale: data?.priceSale,
        titleProperty: data?.title
      }


      const valueSaleFinancing = parseInt(data?.priceSale?.replace(/[^0-9]/gi, ""));
      const valueTextFinancing = valueSaleFinancing.toString()
      const valueTextFormatFinancing = valueTextFinancing?.replace("00", "");

    const Calculate = valueTextFormatFinancing * 0.8
    console.log(Calculate)
    console.log("Calculate")
      const finacingValue = Calculate.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'});

     async function handleNewContactModal() {
        if(name === "" && whatsapp === "" && email === "" ) {
            toast.error("Preencha todas as informações")
           return;
        } 

        const dataInfo = {        
            idProperty: data?.id,
            idCompany: data?.idCompany,
            nameCompany: data?.nameCompany,
            idClient: user?.id,
            name,
            email,
            whatsapp,
        
        }
        console.log(dataInfo);

        await api.post(`/financing`, dataInfo).then((res) => {
            window.open("https://simulador.credihome.com.br/simulador/?partnerId=45242")
        }).catch((error) => {
            console.log(error)
        })


    }

    Modal.setAppElement('#root');

    return (
       <div className="Property">
        <Helmet>
            <title>{data?.title} - Sua Chave</title>
            <meta property="og:image" content={data?.featuredImage} data-react-helmet="true"/>
            <meta property="og:url" content={`https://www.suachave.com.br/imovel/${id}`} data-react-helmet="true" />
            <meta property="og:title" content={data?.title} data-react-helmet="true" />
            <meta property="og:description" content={`${data?.subType} - ${data?.status} - ${data?.city} - ${data?.uf} `} data-react-helmet="true" />

        </Helmet>
        <Navbar2 />
        <div className="main">
          <SliderImages images={data?.images} id={data?.id} />
          <div className="data">
          <div className="propertyUnic">
          {data?.availability === "Disponível" ?
            <div className="targets">

                {new Date(data?.created_at).getDate() === new Date().getDate() &&
                new Date(data?.created_at).getMonth() === new Date().getMonth() &&
                new Date(data?.created_at).getFullYear() === new Date().getFullYear()
                ? 
            <div className="featured">
                        <p>Novo</p>
                    </div>
                    : ""
             }

                    <div className="ref">
                        <p>REF.: {data?.id?.toUpperCase()}</p>
                    </div>
                    {data?.exclusive === "Não" || data?.exclusive === "" || data?.exclusive === null || data?.exclusive === undefined ? ""
                        :
                    <div className="exclusive">
                        <p>Imóvel Exclusivo</p>
                    </div>
                    }

                    {/* <NewMessageProperty idProperty={data?.id} idCompany={data?.idCompany} imageProperty={data?.featuredImage}/> */}
                    <NewScheduling idProperty={data?.id} idCompany={data?.idCompany} title={data?.title} image={data?.featuredImage} type={data?.type} subType={data?.subType}/>
                    {/* ViewProperty está no agendamento */}
                    <div className="iconAction">
                    <NewShare idProperty={data?.id} title={`${data?.subType} - ${data?.status} (${data?.city} / ${data?.uf})`} image={data?.featuredImage}/>
                    <NewFavorite idProperty={data?.id} idCompany={data?.idCompany} page={"yes"}/>
                    </div>

            </div>
            : ""}
            {data?.availability !== "Disponível" ?
                    <div className={
                        data?.availability === "Vendido" ? "availability1" : 
                        data?.availability === "Alugado" ? "availability2" : 
                        data?.availability === "Indisponível" ? "availability3" : 
                        "availability3"
                    }>
                        <p>Imóvel {data?.availability}</p>
                    </div>
                    : ""}

                <div className="text">
                    <h2>{data?.title}</h2>
                    <h5><IoHomeOutline />{data?.status} - {data?.type} - {data?.subType}</h5>
                    <h5>{data?.district === "" && data?.city === "" && data?.uf === ""?
                    <>
                    <IoLocationOutline /> 
                    Solicite a localização
                    </>
                    :
                    <>
                     <IoLocationOutline />{data?.road !== "" || data?.road === "undefined" ? `${data?.road} - ` : ""}
                     {/* {data?.number === "" ? "" : `${data?.number}, `} */}
                     {/* {data?.complement !== "" || data?.complement === "undefined" ? `${data?.complement} - ` : ""} */}
                     {data?.district} - {data?.city} - {data?.uf}
                     {data?.cep !== "" || data?.cep === "undefined" ? ` - CEP: ${data?.cep} ` : ""}
                     {/* {data?.reference !== "" || data?.reference === "undefined" ? ` - ${data?.reference} ` : ""} */}
                    </>
                    }
                    </h5>
                    {data?.financing === "Sim" && data?.availability === "Disponível"?
                    <div className="status">
                        <p>Aceita financiamento</p>
                    </div>
                    : ""}

                   
                    <h4>Sobre o imóvel</h4>
                   
                    <h4 className="description">{data?.description}</h4>

                    <div className="iconsBox">
                    {data?.bedroom === "0" ? "" :
                        <div className="iconUnicBox">
                            <div className="simbolBox">
                                <IoBedOutline />
                            <p>Quartos</p>
                            </div>
                            <p>{data?.bedroom}</p>
                        </div>
                    }
                        {data?.suite === "0" ? "" :
                        <div className="iconUnicBox">
                            <div className="simbolBox">
                                <TbBath />
                            <p>Suítes</p>
                            </div>
                            <p>{data?.suite}</p>
                        </div>
                        }
                        {data?.restroom === "0" ? "" :
                        <div className="iconUnicBox">
                            <div className="simbolBox">
                                <MdOutlineShower />
                            <p>Banheiro</p>
                            </div>
                            <p>{data?.restroom}</p>
                        </div>
                        }
                        {data?.garage === "0" ? "" :
                        <div className="iconUnicBox">
                            <div className="simbolBox">
                                <GiHomeGarage />
                                <p>Vagas</p>
                            </div>
                                <p>{data?.garage}</p>
                        </div>
                        }
                          {data?.pets === "Não" || data?.pets === "" ? "" :
                        <div className="iconUnicBox">
                            <div className="simbolBox">
                                <TbBone />
                            <p>Aceita pets</p>
                            </div>
                            <p>{data?.pets}</p>
                        </div>
                        }
                         {data?.furnished === "Não" || data?.furnished === ""? "" :
                        <div className="iconUnicBox">
                            <div className="simbolBox">
                                <TbSofa />
                                <p>Mobilhado</p>
                            </div>
                                <p>{data?.furnished}</p>
                        </div>
                        }
                        {data?.totalArea === "" ? "" :
                        <div className="iconUnicBox">
                            <div className="simbolBox">
                                <TfiRulerAlt2 />
                                <p>Área total</p>
                            </div>
                                <p>{data?.totalArea} M<sup>2</sup></p>
                        </div>
                            }
                        {data?.buildingArea === "" ? "" :
                        <div className="iconUnicBox">
                            <div className="simbolBox">
                                <IoCrop />
                                <p>Construída</p>
                            </div>
                                <p>{data?.buildingArea} M<sup>2</sup></p>
                        </div>
                        }
                    </div>


                    <div className="characteristics">
                        {data?.characteristcs.map((item) => {
                            return (
                        <div className="item" key={item.id}>
                            <IoCheckmarkSharp /> <p>{item.item}</p> 
                        </div>
                            )
                        })}
                    </div>
                    <div className="infosCompanyMobile">

                    {data.status === "Aluguel" ?
                    <>
                    {data.priceRent === "" ? 
                     <div className="pricing">
                     <h5>Aluguel / <span>Mensal</span></h5>
                     <h3><span>Valor não definido</span></h3>
                 </div>
                  :
                    <div className="pricing">
                        <h5>Aluguel / <span> {data.textRent}</span></h5>
                        <h2>R$ <span>{data.priceRent}</span></h2>
                    </div>
                    }
                    {data.condominium === "" || data.condominium === "0,00" ? ""  :
                    <div className="otherPrincings">
                        <h5>Condomínio</h5>
                        <h5>R$ {data.condominium}</h5>
                    </div>
                        }
                     {data.iptu === "" || data.iptu === "0,00" ? "" :
                    <div className="otherPrincings">
                        <h5>IPTU</h5>
                        <h5>R$ {data.iptu}</h5>
                    </div>
                     }
                     {data.otherPrices === "" || data.otherPrices === "0,00" ? ""  :
                    <div className="otherPrincings">
                        <h5>Outros encargos</h5>
                        <h5>R$ {data.otherPrices}</h5>
                    </div>
                     }
                      {data?.condominium ===  "" && data?.iptu === "" && data?.otherPrices === "" ?     
                        <div className="pricingTotal">
                            <h4>Encargos</h4>
                            <h5>Solicite informações</h5>
                        </div>
                      :
                    <div className="pricingTotal">
                        <h4>Total encargos</h4>
                         <h4>{ResultBRL}</h4>
                     </div>
                    }
                     {data.priceSale === "" ? 
                        ""
                     :
                     <div className="pricing">
                         <h5>Venda</h5>
                         <h2>R$ <span>{data.priceSale}</span></h2>
                     </div>
                     }
                  
                    </>
                    : data.status === "Venda" ?
                    <>
                       {data.priceSale === "" ? 
                        <div className="pricing">
                            <h5>Venda</h5>
                            <h3><span>Valor não definido</span></h3>
                        </div>
                     :
                     <div className="pricing">
                         <h5>Venda</h5>
                         <h2>R$ <span>{data.priceSale}</span></h2>
                     </div>
                     }
                  
                   {data.condominium === "" || data.condominium === "0,00" ? ""  :
                   <div className="otherPrincings">
                       <h5>Condomínio</h5>
                       <h5>R$ {data.condominium}</h5>
                   </div>
                       }
                    {data.iptu === "" || data.iptu === "0,00" ? ""  :
                   <div className="otherPrincings">
                       <h5>IPTU</h5>
                       <h5>R$ {data.iptu}</h5>
                   </div>
                    }
                    {data.otherPrices === "" || data.otherPrices === "0,00" ? ""  :
                   <div className="otherPrincings">
                       <h5>Outros encargos</h5>
                       <h5>R$ {data.otherPrices}</h5>
                   </div>
                    }
                     {data?.condominium ===  "" && data?.iptu === "" && data?.otherPrices === "" ? "" :
                   <div className="pricingTotal">
                       <h4>Total encargos</h4>
                        <h4>{ResultBRL}</h4>
                    </div>
                   }
                  
                   </>
                   : data.status === "Aluguel e Venda" ?
                   <>
                     {data.priceRent === "" ? 
                     <div className="pricing">
                     <h5>Aluguel / <span>Mensal</span></h5>
                     <h3><span>Valor não definido</span></h3>
                 </div>
                  :
                    <div className="pricing">
                        <h5>Aluguel / <span> {data.textRent}</span></h5>
                        <h2>R$ <span>{data.priceRent}</span></h2>
                    </div>
                    }
                    {data.condominium === "" || data.condominium === "0,00" ? ""  :
                    <div className="otherPrincings">
                        <h5>Condomínio</h5>
                        <h5>R$ {data.condominium}</h5>
                    </div>
                        }
                     {data.iptu === "" || data.iptu === "0,00" ? "" :
                    <div className="otherPrincings">
                        <h5>IPTU</h5>
                        <h5>R$ {data.iptu}</h5>
                    </div>
                     }
                     {data.otherPrices === "" || data.otherPrices === "0,00" ? ""  :
                    <div className="otherPrincings">
                        <h5>Outros encargos</h5>
                        <h5>R$ {data.otherPrices}</h5>
                    </div>
                     }
                      {data?.condominium ===  "" && data?.iptu === "" && data?.otherPrices === "" ? 
                        <div className="pricingTotal">
                        <h4>Encargos</h4>
                         <h5>Solicite informações</h5>
                     </div>
                     :
                    <div className="pricingTotal">
                        <h4>Total encargos</h4>
                         <h4>{ResultBRL}</h4>
                     </div>
                    }
                     {data.priceSale === "" ? 
                        <div className="pricing">
                            <h5>Venda</h5>
                            <h3><span>Valor não definido</span></h3>
                        </div>
                     :
                     <div className="pricing">
                         <h5>Venda</h5>
                         <h2>R$ <span>{data.priceSale}</span></h2>
                     </div>
                     }
                  
              
                 
                  </>
                  : ""
                   
                    }

                    <CompanyInfo idProperty={data?.id} idCompany={data?.idCompany} nameCompany={data?.fantasyName}/>

                    {data.priceSale === "" ? "" :
                        <div className="financing">
                            <div className="financingClient">
                            <h5>Financie este imóvel até</h5>
                            <h2>{finacingValue}</h2>
                            <h6>* Sujeito a análise</h6>
                            </div>
                            <div className="financingFipe">
                            <h5>Ver Parcelas do financiamento</h5>
                            {/* <h2>R$ {data?.valueFipe}</h2> */}
                        <button onClick={handleOpenModal}>Simular financiamento</button>
                            </div>
                        </div>
                        }
                    </div>

                    {data?.video === "" ? "" :
                    <div className="video">
                    <iframe width="100%" height="300" src={data?.video} title="YouTube video player" frameBorder="0"
                    style={{border:"0px", borderRadius: "20px"}}
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </div>
                    }
                        {data.district === "" && data.city === "" && data.uf === "" ? "" :
                        <div className="map">
                            <iframe
                            width="100%" height="300"
                            frameBorder="0" style={{border:"0px", borderRadius: "20px"}} 
                            referrerpolicy="no-referrer-when-downgrade"
                            src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyCZllXD0czNd_oeF0u_o9LUVJ2bCd1K4p8&q=${data?.district}, ${data?.city} - ${data?.uf}, ${data?.cep}`}
                            allowFullScreen>
                            </iframe>
                           <h6> * Endereço aproximado</h6>
                        </div> 
                        }


                        <NewAlertClient
                        status={data?.status} district={data?.district} city={data?.city} uf={data?.uf} type={data?.type}
                        subType={data?.subType} bedroom={data?.bedroom} suite={data?.suite} restroom={data?.restroom}
                        garage={data?.garage} pets={data?.pets} furnished={data?.furnished} idProperty={data?.id}
                        />
                    </div>
                </div>

                <div className="infosCompany">
                {data.status === "Aluguel" ?
                    <>
                    {data.priceRent === "" ? 
                     <div className="pricing">
                     <h5>Aluguel / <span>Mensal</span></h5>
                     <h3><span>Valor não definido</span></h3>
                 </div>
                  :
                    <div className="pricing">
                        <h5>Aluguel / <span> {data.textRent}</span></h5>
                        <h2>R$ <span>{data.priceRent}</span></h2>
                    </div>
                    }
                    {data.condominium === "" || data.condominium === "0,00" ? ""  :
                    <div className="otherPrincings">
                        <h5>Condomínio</h5>
                        <h5>R$ {data.condominium}</h5>
                    </div>
                        }
                     {data.iptu === "" || data.iptu === "0,00" ? "" :
                    <div className="otherPrincings">
                        <h5>IPTU</h5>
                        <h5>R$ {data.iptu}</h5>
                    </div>
                     }
                     {data.otherPrices === "" || data.otherPrices === "0,00" ? ""  :
                    <div className="otherPrincings">
                        <h5>Outros encargos</h5>
                        <h5>R$ {data.otherPrices}</h5>
                    </div>
                     }
                      {data?.condominium ===  "" && data?.iptu === "" && data?.otherPrices === "" ? 
                        <div className="pricingTotal">
                          <h4>Encargos</h4>
                          <h5>Solicite informações</h5>
                        </div>
                                            :
                    <div className="pricingTotal">
                        <h4>Total encargos</h4>
                         <h4>{ResultBRL}</h4>
                     </div>
                    }
                     {data.priceSale === "" ? 
                        ""
                     :
                     <div className="pricing">
                         <h5>Venda</h5>
                         <h2>R$ <span>{data.priceSale}</span></h2>
                     </div>
                     }
                  
                    </>
                    : data.status === "Venda" ?
                    <>
                       {data.priceSale === "" ? 
                        <div className="pricing">
                            <h5>Venda</h5>
                            <h3><span>Valor não definido</span></h3>
                        </div>
                     :
                     <div className="pricing">
                         <h5>Venda</h5>
                         <h2>R$ <span>{data.priceSale}</span></h2>
                     </div>
                     }
                  
                   {data.condominium === "" || data.condominium === "0,00" ? ""  :
                   <div className="otherPrincings">
                       <h5>Condomínio</h5>
                       <h5>R$ {data.condominium}</h5>
                   </div>
                       }
                    {data.iptu === "" || data.iptu === "0,00" ? ""  :
                   <div className="otherPrincings">
                       <h5>IPTU</h5>
                       <h5>R$ {data.iptu}</h5>
                   </div>
                    }
                    {data.otherPrices === "" || data.otherPrices === "0,00" ? ""  :
                   <div className="otherPrincings">
                       <h5>Outros encargos</h5>
                       <h5>R$ {data.otherPrices}</h5>
                   </div>
                    }
                     {data?.condominium ===  "" && data?.iptu === "" && data?.otherPrices === "" ? "" :
                   <div className="pricingTotal">
                       <h4>Total encargos</h4>
                        <h4>{ResultBRL}</h4>
                    </div>
                   }
                  
                   </>
                   : data.status === "Aluguel e Venda" ?
                   <>
                     {data.priceRent === "" ? 
                     <div className="pricing">
                     <h5>Aluguel / <span>Mensal</span></h5>
                     <h3><span>Valor não definido</span></h3>
                 </div>
                  :
                    <div className="pricing">
                        <h5>Aluguel / <span> {data.textRent}</span></h5>
                        <h2>R$ <span>{data.priceRent}</span></h2>
                    </div>
                    }
                    {data.condominium === "" || data.condominium === "0,00" ? ""  :
                    <div className="otherPrincings">
                        <h5>Condomínio</h5>
                        <h5>R$ {data.condominium}</h5>
                    </div>
                        }
                     {data.iptu === "" || data.iptu === "0,00" ? "" :
                    <div className="otherPrincings">
                        <h5>IPTU</h5>
                        <h5>R$ {data.iptu}</h5>
                    </div>
                     }
                     {data.otherPrices === "" || data.otherPrices === "0,00" ? ""  :
                    <div className="otherPrincings">
                        <h5>Outros encargos</h5>
                        <h5>R$ {data.otherPrices}</h5>
                    </div>
                     }
                      {data?.condominium ===  "" && data?.iptu === "" && data?.otherPrices === "" ?
                        <div className="pricingTotal">
                          <h4>Encargos</h4>
                          <h5>Solicite informações</h5>
                        </div>
                      :
                    <div className="pricingTotal">
                        <h4>Total encargos</h4>
                         <h4>{ResultBRL}</h4>
                     </div>
                    }
                     {data.priceSale === "" ? 
                        <div className="pricing">
                            <h5>Venda</h5>
                            <h3><span>Valor não definido</span></h3>
                        </div>
                     :
                     <div className="pricing">
                         <h5>Venda</h5>
                         <h2>R$ <span>{data.priceSale}</span></h2>
                     </div>
                     }
                  
              
                 
                  </>
                  : ""
                   
                    }


                <CompanyInfo idProperty={data?.id} idCompany={data?.idCompany} nameCompany={data?.fantasyName}/>

                    {data.priceSale === "" ? "" :
                        <div className="financing">
                            <div className="financingClient">
                            <h5>Financie este imóvel até</h5>
                            <h2>{finacingValue}</h2>
                            <h6>* Sujeito a análise</h6>
                            </div>
                            <div className="financingFipe">
                            <h5>Ver Parcelas do financiamento</h5>
                            {/* <h2>R$ {data?.valueFipe}</h2> */}
                        <button onClick={handleOpenModal}>Simular financiamento</button>
                            </div>
                        </div>
                        }
                </div>

                </div>
        {/* <DownloadApp /> */}
        </div>
        <Footer />

        <Modal isOpen={isOpenModal} onRequestClose={handleCloseModal}
            overlayClassName="react-modal-overlay"
            className="react-modal-content">
            <button type="button" className="react-modal-button" onClick={handleCloseModal}>
            <IoCloseOutline /> 
            </button>
            <div className="content-modal-Message">
            <div className="itensModalMessage">
                    <h2>Vamos iniciar sua simulação </h2>

                    <form action="">
                        <span>Nome</span>
                        <input type="text" placeholder="Nome completo" value={name} onChange={e => setName(e.target.value)}/>
                        <span>Whatsapp</span>
                        <input type="text" placeholder="(XX)XXXXX-XXXX" value={whatsapp} onChange={e => setWhatsapp(e.target.value)}/>
                        <span>Email</span>
                        <input type="text" placeholder="seuemail@provedor.com" value={email} onChange={e => setEmail(e.target.value)}/>
                    </form>

                    <p>Por que pedimos essas informações? <IoAlertCircle data-tip data-for='Novo Cliente' size={22}/></p>
                    <ReactTooltip id='Novo Cliente' place="bottom" type="dark" effect="solid">
                      <span>Passar essas informações permite que a imobiliária ou corretor  consiga  <br /> entrar em contato em caso de uma eventual aprovação no financiamento.</span>
                    </ReactTooltip>

                    <button onClick={handleNewContactModal}>Iniciar simulação</button>

            </div>
            </div>
            </Modal>
       </div>
      )
}