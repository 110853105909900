import { Footer } from "../../components/Footer/Footer";
import Navbar2 from "../../components/Nav/Navbar";
import Caixa from "../../assets/images/logos/caixa.svg";
import Santander from "../../assets/images/logos/santander.svg";
import Bradesco from "../../assets/images/logos/bradesco.svg";
import Itau from "../../assets/images/logos/itau2.svg";
import Brasil from "../../assets/images/logos/brasil.svg";
import Inter from "../../assets/images/logos/inter.svg";
import "./simulator.css";
import {FiDollarSign} from "react-icons/fi";
import {IoCalculatorOutline, IoCalendarOutline, IoPeopleOutline, IoBusinessOutline, IoWalletOutline, IoAlertCircle} from "react-icons/io5";
import { useState } from "react";
import api from "../../services/api";
import ReactTooltip from 'react-tooltip';
import {toast} from 'react-toastify';

export function Simulator() {
    const Local = localStorage.getItem("suachave");
    const user = JSON.parse(Local);

    const [name, setName] = useState("");
    const [whatsapp, setWhatsapp] = useState("");
    const [email, setEmail] = useState("");

    async function handleNewContactModal() {
        if(name === "" && whatsapp === "" && email === "" ) {
            toast.error("Preencha todas as informações")
           return;
        } 

        const dataInfo = {        
            idProperty: "",
            idCompany: "",
            nameCompany: "",
            idClient: user?.id,
            name,
            email,
            whatsapp,
        
        }
        console.log(dataInfo);

        await api.post(`/financing`, dataInfo).then((res) => {
            window.open("https://simulador.credihome.com.br/simulador/?partnerId=45242")
        }).catch((error) => {
            console.log(error)
        })


    }



    return (
        <div className="Simulator">
            <Navbar2 />
            <div className="topImageSimulator">
                <div className="back">
            <h1>Nós temos o imóvel dos seus sonhos!</h1>
                <h3>E também as melhores oções de financiamento.</h3>
                </div>
            </div>

            <div className="financer">
            <h2>Por que <span>financiar</span> um imóvel?</h2>
                <h4>Veja 6 vantagens de realizar um financiamento imobiliário.</h4>

                <div className="benefits">
                    <div className="benefitUnic">
                        <FiDollarSign/>
                         <div className="text">
                        <h5>Tenha sua carta de crédito para negociar o melhor imóvel.</h5>
                        </div>
                    </div>
                    <div className="benefitUnic">
                        <IoCalculatorOutline/>
                         <div className="text">
                        <h5>Financie até 80% do valor de imóveis a partir de R$ 90 mil.</h5>
                        </div>
                    </div>
                    <div className="benefitUnic">
                        <IoBusinessOutline/>
                         <div className="text">
                        <h5>Imóveis residenciais em até 35 anos e comerciais em até 30 anos.</h5>
                        </div>
                    </div>
                    <div className="benefitUnic">
                        <IoCalendarOutline/>
                         <div className="text">
                        <h5>Escolha entre parcelas fixas ou atualizáveis.</h5>
                        </div>
                    </div>
                    <div className="benefitUnic">
                        <IoPeopleOutline/>
                         <div className="text">
                        <h5>Some sua renda com mais uma pessoa, mesmo sem parentesco.</h5>
                        </div>
                    </div>
                    <div className="benefitUnic">
                        <IoWalletOutline/>
                        <div className="text">
                        <h5>Utilize o FGTS na compra de imóveis de até R$ 1,5 milhão. </h5>
                        <h6>De acordo com as regras vigentes publicadas pela Caixa Econômica Federal</h6>
                        </div>
                    </div>
                </div>
            </div>

            <div className="stepesFinances">
                            <h2>5 passos do <span>financiamento</span></h2>
                <h4>Conheça cada etapa do processo de financiamento imobiliário.</h4>

                <div className="stepesList">
                <div className="stepeUnic">
                    <h1>1</h1>
                    <div className="textStepe">
                        <h4>Simulação e aprovação de crédito </h4>
                        <h5>Preencha os seus dados e os do imóvel para simular e solicitar a análise de crédito. </h5>
                    </div>
                </div>
                <div className="stepeUnic2">
                    <div className="textStepe2">
                        <h4>Cadastro e documentação</h4>
                        <h5>Cadastre as informações da proposta e envie os documentos solicitados. </h5>
                    </div>
                    <h1>2</h1>
                </div>
                <div className="stepeUnic">
                    <h1>3</h1>
                    <div className="textStepe">
                        <h4>Análise de documentos e vistoria do imóvel</h4>
                        <h5>Após enviar seus documentos, é hora de acompanhar a análise técnica e marcar a vistoria. </h5>
                    </div>
                </div>
                <div className="stepeUnic2">
                    <div className="textStepe2">
                        <h4>Contrato</h4>
                        <h5>Após a conclusão das análises, você irá até a agência assinar o contrato.</h5>
                    </div>
                    <h1>4</h1>
                </div>
                <div className="stepeUnic">
                    <h1>5</h1>
                    <div className="textStepe">
                        <h4>Liberação de crédito</h4>
                        <h5>Após a entrega do contrato registrado ao banco selecionado e matrícula atualizada, o crédito será liberado ao vendedor.</h5>
                    </div>
                </div>
                </div>
            </div>
             
             <div className="bank">
                <div className="back2">
                <h2>Simule as suas condições de financiamento</h2>
                <h4>Preencha os dados para iniciar uma simulação gratuita.</h4>
              
                <div className="form">
                        <span>Nome</span>
                        <input type="text" placeholder="Nome completo" value={name} onChange={e => setName(e.target.value)}/>
                        <span>Whatsapp</span>
                        <input type="text" placeholder="(XX)XXXXX-XXXX" value={whatsapp} onChange={e => setWhatsapp(e.target.value)}/>
                        <span>Email</span>
                        <input type="text" placeholder="seuemail@provedor.com" value={email} onChange={e => setEmail(e.target.value)}/>

                    <p>Por que pedimos essas informações? <IoAlertCircle data-tip data-for='Novo Cliente' size={22}/></p>
                    <ReactTooltip id='Novo Cliente' place="bottom" type="dark" effect="solid">
                      <span>Passar essas informações permite que a imobiliária ou corretor  consiga  <br /> entrar em contato em caso de uma eventual aprovação no financiamento.</span>
                    </ReactTooltip>

                    <button onClick={handleNewContactModal}>Iniciar simulação</button>

            </div>
                </div>
                </div>
                <Footer />
        </div>
    )
}