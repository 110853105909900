import "./propertyNotFound.css"
import notFoundImage from "../../assets/images/svg/404property.svg";
import logo from "../../assets/images/Logo.png";


export function PropertyNotFound() {

    function handleBack() {
        window.history.back();
        console.log("Back");
    }


    return (
<div className="PropertyNotFound">
    <div className="MainAbout">
        <div className="logo">
             <img src={logo} alt="" />
        </div>
              <img src={notFoundImage} alt="" />
        <h1>Imóvel não encontrado</h1>
        <h5>Parece que este imóvel não está mais em nossa base de dados.</h5>
        <div className="buttons">
            <a href="/imoveis">Ver todos os imóveis</a>
            <a href="/">Ir para home</a>
        </div>
    </div>
</div>
    )
}